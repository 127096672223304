// Environment variable management for the client side
export const config = {
  affiliatesWebAppUrl: process.env.AFFILIATES_WEB_APP_URL || 'https://affiliates.swile.co',
  swileFaqUrl: process.env.SWILE_FAQ_URL || 'https://help.swile.co/hc',
  swileDomain: process.env.SWILE_DOMAIN || 'swile.co',
  websiteUrl: process.env.WEBSITE_URL || 'https://www.swile.co',
  googleAnalyticsTrackingId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || 'GTM-5G8CMML',
  googleAnalyticsDatalayerId: process.env.NEXT_PUBLIC_GOOGLE_DATALAYER_ID || 'AW-856414919',
  skipTunnelStepCheck: process.env.NEXT_PUBLIC_SKIP_TUNNELS_STEP_CHECK === 'true',
}
