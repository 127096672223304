import * as Sentry from '@sentry/node'
import { Request } from 'express'

/**
 * Initialize Sentry and export it.
 *
 * Helper to avoid duplicating the init() call in every /pages/api file.
 * Also used in pages/_app for the client side, which automatically applies it for all frontend pages.
 */
if (process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    enabled: true,
    environment: process.env.NODE_ENV,
  })
}

export const configureAPISentryScope = ({ cookies, url, headers, query, method }: Request): void => {
  Sentry.configureScope((scope) => {
    scope.setTag('host', headers.host)
    scope.setTag('url', url)
    scope.setTag('method', method)
    scope.setContext('query', query)
    scope.setContext('cookies', cookies)
    scope.setContext('headers', headers)
  })
}

export default Sentry
