import React from 'react'

import Head from 'next/head'

import { useIntl, defineMessages } from 'react-intl'

import useTabVisibility from '@hooks/useTabVisibility'
import { GA_TRACKING_ID } from '@services/client/analytics/googleTag'

const i18n = defineMessages({
  defaultTitle: {
    id: 'meta.title',
  },
  inactiveTitle: {
    id: 'meta.inactive_title',
  },
  description: {
    id: 'meta.description',
  },
  ogDescription: {
    id: 'meta.og_description',
  },
  ogTitle: {
    id: 'meta.og_title',
  },
})

const HeadMetas: React.FunctionComponent = () => {
  const intl = useIntl()
  const isTabVisible = useTabVisibility()

  return (
    <Head>
      <title>{isTabVisible ? intl.formatMessage(i18n.defaultTitle) : intl.formatMessage(i18n.inactiveTitle)}</title>
      {/* Favicon */}
      <link rel="shortcut icon" type="image/x-icon" href="/images/favicon/favicon.ico" />
      <link rel="icon" type="image/x-icon" href="/images/favicon/favicon.ico" />
      <link rel="icon" type="image/png" href="/images/favicon/favicon16x16.png" />
      <link rel="icon" type="image/png" href="/images/favicon/favicon32x32.png" />
      <link rel="icon" type="image/png" href="/images/favicon/favicon64x64.png" />
      {/* Social */}
      <meta name="description" content={intl.formatMessage(i18n.description)} />
      <meta property="og:image" content="/images/og_image.png" />
      <meta property="og:title" content={intl.formatMessage(i18n.ogTitle)} />
      <meta property="og:description" content={intl.formatMessage(i18n.ogDescription)} />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${GA_TRACKING_ID}');`,
        }}
      ></script>
    </Head>
  )
}

export default HeadMetas
