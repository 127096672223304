import { useState, useEffect } from 'react'

const EVENT_VISIBILITY_CHANGE = 'visibilitychange'
const STATE_VISIBLE = 'visible'

const useTabVisibility = (): boolean => {
  const [isTabVisible, setTabVisible] = useState(
    typeof document !== 'undefined' ? document.visibilityState === STATE_VISIBLE : true
  )

  useEffect(() => {
    const updateTabVisibility = (): void => {
      const isVisible = typeof document !== 'undefined' ? document.visibilityState === STATE_VISIBLE : true
      setTabVisible(isVisible)
    }

    document.addEventListener(EVENT_VISIBILITY_CHANGE, updateTabVisibility)
    return () => {
      document.removeEventListener(EVENT_VISIBILITY_CHANGE, updateTabVisibility)
    }
  })

  return isTabVisible
}

export default useTabVisibility
