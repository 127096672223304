import en from './en_US.json'
import fr from './fr_FR.json'

import type { ValueOf } from 'type-fest'

export const SupportedLanguage = {
  FR: 'fr',
  EN: 'en',
} as const
export type SupportedLanguage = ValueOf<typeof SupportedLanguage>

export const SupportedLocale = {
  FR_FRANCE: 'fr-FR',
  EN_UNITED_STATES: 'en-US',
} as const
export type SupportedLocale = ValueOf<typeof SupportedLocale>

export const WebsiteSupportedLocale = {
  FR: 'fr-fr',
  EN: 'en',
} as const
export type WebsiteSupportedLocale = ValueOf<typeof WebsiteSupportedLocale>

export const defaultLocale = SupportedLocale.EN_UNITED_STATES

const locales: Record<SupportedLanguage, Record<string, string>> = { en, fr }

export default locales
