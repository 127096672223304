import { ErrorInfo } from 'react'

import App, { AppContext, AppInitialProps } from 'next/app'

import { StylesProvider } from '@material-ui/styles'
import { IntlProvider } from 'react-intl'
import { createGlobalStyle } from 'styled-components'

import { COLORS } from '@themenu/design-system'

import HeadWrapper from '../components/HeadWrapper/HeadWrapper'
import { SupportedLocale, defaultLocale } from '../i18n/locales'
import { getLanguage, getTranslationMessages } from '../i18n/utils'
import Sentry from '@lib/sentry'

const GlobalStyle = createGlobalStyle`
    html {
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    *, *::before, *::after {
      box-sizing: inherit;
    }
    strong, b {
      font-weight: 700;
    }
    body {
      color: rgba(0, 0, 0, 0.87);
      margin: 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.43;
      letter-spacing: 0.01071em;
      background-color: ${COLORS.WHITE[100]};
    }
    @media print {
      body {
        background-color: #fff;
      }
    }
    body::backdrop {
      background-color: ${COLORS.WHITE[100]};
    }
    a {
      color: ${COLORS.PURPLE[900]}
    }
`

class AffiliatesOnboardingTunnel extends App {
  static async getInitialProps({ Component, ctx }: AppContext): Promise<AppInitialProps> {
    let pageProps = {}

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    return {
      pageProps,
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key as keyof ErrorInfo])
      })

      Sentry.captureException(error)
    })

    super.componentDidCatch?.(error, errorInfo)
  }

  public render(): JSX.Element {
    const { Component, pageProps, router } = this.props
    const locale = (router.locale as SupportedLocale) || defaultLocale
    const language = getLanguage(locale)
    return (
      <StylesProvider injectFirst>
        <IntlProvider locale={language} messages={getTranslationMessages(language)}>
          <HeadWrapper />
          <GlobalStyle />
          <Component {...pageProps} />
        </IntlProvider>
      </StylesProvider>
    )
  }
}

export default AffiliatesOnboardingTunnel
