import { useRouter } from 'next/router'

import { config } from '../config/client'
import locales, { SupportedLanguage, SupportedLocale, WebsiteSupportedLocale } from './locales'

export function getLanguage(supportedLocale: SupportedLocale): SupportedLanguage {
  return supportedLocale.substr(0, 2) as SupportedLanguage
}

function getWebsiteLocale(supportedLocale: SupportedLocale): WebsiteSupportedLocale {
  const language = getLanguage(supportedLocale)

  switch (language) {
    case SupportedLanguage.EN:
      return WebsiteSupportedLocale.EN

    default:
      return WebsiteSupportedLocale.FR
  }
}

export function getTranslationMessages(locale: SupportedLanguage): Record<string, string> {
  return locales[locale]
}

export function redirectWithLocale(redirectionPath: string): string {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter()
  const locale = router?.locale ? router.locale : SupportedLocale.FR_FRANCE

  const websiteUrl = config.websiteUrl

  return redirectionPath.replace(websiteUrl, websiteUrl.concat('/', getWebsiteLocale(locale as SupportedLocale)))
}
