import { config } from '../../config/client'

declare global {
  interface Window {
    // eslint-disable-next-line
    ga: any
  }
}

export const GA_TRACKING_ID = config.googleAnalyticsTrackingId
export const GA_DATALAYER_ID = config.googleAnalyticsDatalayerId

let lastPageTracked = ''

const formatCurrentLocation = (path: string): string =>
  `${window.location.protocol}//${window.location.hostname}${path}`
const isGoogleAnalyticsInitialized = (): boolean => 'ga' in window

export const getTrackerName = (): string | undefined => {
  if (!isGoogleAnalyticsInitialized() || !window.ga.getAll) {
    return
  }

  const trackers = window.ga.getAll()

  return !trackers || trackers.length === 0 || !trackers[0].get ? undefined : trackers[0].get('name')
}

const sendPageView = (path: string, tracker = 'gtm1'): void => {
  const event = `${tracker}.send`

  window.ga(event, {
    hitType: 'pageview',
    location: formatCurrentLocation(path),
  })
  lastPageTracked = path
}

export const logPageView = (path = document.location.pathname): void => {
  const trackerName = getTrackerName()

  if (isGoogleAnalyticsInitialized() && !trackerName) {
    window.ga(() => logPageView(path))
  }

  if (isGoogleAnalyticsInitialized() && path !== lastPageTracked && trackerName) {
    sendPageView(path, trackerName)
  }
}
